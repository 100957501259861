.notification_container {
    position: fixed;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 9999;
    max-width: 250px;
  }
  .notification {
    padding: 15px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
    transition: transform 0.3s ease, opacity 0.3s ease;
    text-align: center;
    position: relative;
  }

  .close{
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 900;
    border: none;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
  }
  
  .notification.success {
    background-color: var(--neon-green);
  }
  
  .notification.error {
    background-color: var(--coral);
  }
  
  .notification.warning {
    background-color: var(--orange-yellow);
  }
  
  .notification.info {
    background-color: var(--vibrant-purple);
  }
  
  .notification.alert {
    background-color: var(--orange-red);
  }
  