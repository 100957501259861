@import url("./css/root.css");


body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dark-blue);
}

div#root {
  margin: 0 auto;
  background-image: url("./images/background.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5%;
}

div#container {
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  overflow: visible;
  display:flex;
  flex-direction: column;
}
