@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
:root {
  --vibrant-purple: #9e3cfb;
  --neon-green: #00ee89;
  --dark-blue: #121c2b;
  --coral: #ff6f61;
  --orange-yellow: #ff9f1c;
  --gold: #ffd700;
  --light-pink: #ffb6c1;
  --hot-pink: #ff69b4;
  --light-salmon: #ffa07a;
  --orange: #ffa500;
  --orange-red: #ff4500;
  --deep-pink: #ff1493;
  --light-gray: #f9f9f9;
  --dark-gray: #2c3e50;
  --soft-gray: #eaeaea;
  --hover-purple: #b076fc;
}

@keyframes blink-border-error {
  0% {
    border-color: var(--vibrant-purple);
  }
  50% {
    border-color: var(--orange-red);
  }
  100% {
    border-color: var(--vibrant-purple);
  }
}
@keyframes blink-icon-alert {
  0% {
    color: #fff;
  }
  50% {
    color: var(--orange-red);
  }
  100% {
    color: #fff;
  }
}

.flex_row {
  flex-direction: row !important;
  gap: 10px;
}

.fs_1 {
  font-size: 10px !important;
}
.fs_15 {
  font-size: 15px !important;
}

.fs_2 {
  font-size: 20px !important;
}
.fs_25 {
  font-size: 25px !important;
}

.fs_3 {
  font-size: 30px !important;
}
.fs_35 {
  font-size: 35px !important;
}

.error {
  display: block;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  border: 2px solid var(--vibrant-purple);
  border-radius: 15px;
  color: #fff;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  animation: blink-border-error 2s infinite;
}

.error span {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error .errorIcon {
  font-size: 30px;
  padding: 0;
  animation: blink-icon-alert 2s infinite;
}

.error h3 {
  display: block;
  font-size: 13px;
  padding: 0;
}

input.errorInput {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: left;
  border: 2px solid red;
  color: red;
  animation: blink-border-error 2s infinite;
}

/* Para navegadores baseados em WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0; /* Oculta a barra de rolagem */
  height: 0; /* Oculta a barra de rolagem horizontal */
  transition: width 0.3s, height 0.3s; /* Transição suave ao mostrar */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Cor do "thumb" quando visível */
  border-radius: 10px; /* Borda arredondada */
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); /* Cor do trilho */
}

/* Exibe a barra de rolagem ao passar o mouse sobre o elemento com rolagem */
:hover::-webkit-scrollbar {
  width: 8px; /* Mostra a barra de rolagem ao passar o mouse */
  height: 8px; /* Mostra a barra horizontal ao passar o mouse */
}

/* Para Firefox */
* {
  scrollbar-width: none; /* Oculta a barra de rolagem */
  scrollbar-color: transparent transparent; /* Deixa o thumb e track transparentes */
  transition: scrollbar-width 0.3s; /* Transição suave ao mostrar */
}

/* Exibe a barra de rolagem ao passar o mouse ou quando ativa (rolando) */
*:hover,
*:active {
  scrollbar-width: thin; /* Exibe a barra de rolagem fina */
  scrollbar-color: var(--neon-green) rgba(0, 0, 0, 0.1); /* Cores do thumb e track */
}
