@import url("../../../css/root.css");

/* Estilos gerais */
.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.social_networks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: clamp(0vw, 6.77vw, 90px);
}

.social_networks_icons {
  min-width: 70px;
}

.social_networks_icons a {
  color: var(--neon-green);
}

.social_networks div:nth-child(2) {
  color: var(--neon-green);
  display: flex;
  justify-content: space-between;
  width: clamp(0vw, 5.27vw, 70px);
  margin-top: clamp(0vw, 1.5vw, 20px);
}

.links {
  display: flex;
  justify-content: space-around;
  margin-top: clamp(0vw, 3vw, 40px);
  width: 100%;
  font-weight: 700;
}

.partnerships {
  background-color: #fff;
  width: 100%;
  height: auto;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: clamp(0vw, 3vw, 40px);
  border-radius: clamp(0vw, 0.75vw, 10px) clamp(0vw, 0.75vw, 10px) 0 0;
}

.partnerships div {
  margin: 0 clamp(0vw, 0.75vw, 10px);
}

.partnerships h3 {
  font-size: 12px;
  text-align: center;
  color: #8d8e8f;
  margin-bottom: clamp(0vw, 0.75vw, 10px);
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.images img {
  max-width: clamp(110px, 11.14vw, 148px);
  margin: 0 clamp(0vw, 0.75vw, 10px);
  border-left: 1px solid #8d8e8f;
  padding-left: clamp(0vw, 0.75vw, 10px);
}

.images img:nth-child(1) {
  padding-left: 0;
  border: none;
}

/* Media Queries */

@media only screen and (max-width: 575px) {
  .partnerships {
    flex-direction: column;
    height: auto;
  }

  .links {
    flex-direction: column;
    width: auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .links {
    flex-direction: column;
    width: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .links {
    flex-direction: column;
    width: auto;
  }
}
