@import url('../../../css/root.css');

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--dark-blue);
  background-image: url('../../../images/background.png') !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}

.transparent{
  background-color: rgba(0,0,0,0.3);
  background-image: none !important;
}
.loading img {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Media Queries */
@media only screen and (max-width: 575px) {
  .loading img {
    width: 80%; /* Ajustar largura da imagem para telas menores */
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .loading img {
    width: 70%; /* Ajustar largura da imagem para telas pequenas */
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .loading img {
    width: 60%; /* Ajustar largura da imagem para tablets */
  }
}
