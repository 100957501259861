.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: clamp(0vw, 3.38vw, 45px);
}

.header_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: clamp(0vw, 19.58vw, 260px);
}

.logo img {
  width: 100%;
}

.menu_icon {
  font-size: 24px;
  cursor: pointer;
  color: var(--neon-green);
}

.menu_container {
  position: relative;
}

.header_content{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: 15px;
}

.saldo{
  display: flex;
  gap: 20px;
  margin-top: -10px;
}

.money{
  font-size: 12px;
  color: #fff !important;
  display: flex;
  flex-direction: row;
  gap:10px;
  justify-content: center;
  align-items: center;
  font-weight: normal;
}

.money_cash{
  font-size: 14px;
}

.money div:nth-child(1){
  color: var(--neon-green);
  font-size: 24px;
}  

.money div:nth-child(2){
  display: flex;
  flex-direction: column;
}  

.dropdown_menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--dark-blue);
  border: 1px solid var(--neon-green);
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  width:200px;
}

.dropdown_menu li:nth-child(1){
  border-radius: 15px 15px 0 0 ;
  border:none;
}

.dropdown_menu li:last-child{
  border-radius: 0 0 15px 15px;
}

.dropdown_menu li {
  cursor: pointer;
  border-top: 1px solid var(--neon-green);
  width: 100%;
}

.dropdown_menu li a{
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  text-wrap: wrap;
  padding: 7px 0;
}

.dropdown_menu li:hover {
  background-color: var(--vibrant-purple);
}


/* Extra Pequeno (telefone) */
@media only screen and (max-width: 575px) {
  .logo {
    width: 60%;
  }

  .header_buttons {
    width: clamp(0vw, 62vw, 820px);
    padding: 0 2%;
  }

  .saldo{
    flex-direction: column;
    gap:5px
  }

  .dropdown_menu{
    width: 89.6vw;
  }

  
}

/* Pequeno (telefone) */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header_buttons {
    width: clamp(0vw, 62vw, 820px);
    padding: 0;
  }
}

/* Médio (tablet) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_buttons {
    width: clamp(0vw, 38vw, 520px);
  }
}
