@import url("../../../css/root.css");

/**
    COMMON
*/
.btn {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  transition: all linear 0.3s;
  white-space: nowrap;
}

.rounded {
  border-radius: clamp(0vw, 0.9vw, 12px);
}

.active {
  background-color: var(--neon-green) !important;
  color: var(--dark-blue) !important;
}

.bold {
  font-weight: bold;
}

.upper {
  text-transform: uppercase;
}

.min_padding {
  padding: 2px 10px;
}

.size_free {
  width: auto !important;
  white-space: nowrap; /* Corrigido typo: era text-wrap */
  text-align: center;
}

/* Button Sizes and Styles */
.btn,
.big_size {
  width: clamp(0vw, 9.03vw, 120px); /* Default width */
  height: clamp(0vw, 2.25vw, 30px); /* Default height */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(0vw, 1.05vw, 14px); /* Default font size */
  border: none;
}

.middle_size {
  width: 80%;
  height: auto;
  font-size: 14px;
  padding: 5px;
}

.google {
  background-color: #fff;
  color: #333;
  font-weight: 700;
}

.google_icon img {
  margin-right: 8px;
  width: 20px;
}

.arrow_previous{
  font-size: 18px;
  height: 18px;
}

.big_size {
  width: 49%;
  height: clamp(0vw, 4.89vw, 65px);
  font-size: clamp(0vw, 2.25vw, 30px);
  color: var(--neon-green);
  font-weight: 700;
}

/* Button Colors */
.neon_green {
  background-color: var(--neon-green);
  color: var(--dark-blue);
  font-weight: bold;
}

.vibrant_purple {
  background-color: var(--vibrant-purple);
  color: #fff;
  font-weight: bold;
}

.transparent {
  background-color: transparent;
  color: #fff;
  border: 1px solid var(--neon-green);
}

/* Hover Effects */
.hover_purple:hover {
  background-color: var(--vibrant-purple);
  color: #fff;
  border-color: var(--vibrant-purple);
}

.hover_green:hover {
  background-color: var(--neon-green);
  color: var(--dark-blue);
  border-color: var(--neon-green);
  font-weight: 700;
}

/* White Link */
.white_link {
  color: #fff;
  width: auto;
  background-color: transparent;
}

.white_link:hover,
.green_link:hover {
  text-decoration: underline;
}

.green_link {
  color: var(--neon-green);
  width: auto;
  background-color: transparent;
}

/* Media Queries */
@media only screen and (max-width: 575px) {
  .btn {
    width: clamp(0vw, 22.6vw, 300px);
    height: clamp(0vw, 6.03vw, 80px);
    font-size: clamp(0vw, 2.26vw, 30px);
    font-weight: bold;
    z-index: 999; /* Exemplo de propriedade que estava no .btn */
  }

  .big_size {
    width: 99%;
    padding: 0;
    margin: 5px 0;
    height: clamp(0vw, 6.03vw, 80px);
    font-size: clamp(0vw, 3.01vw, 40px);
    font-weight: 700;
  }

  .average_size {
    width: clamp(0vw, 52.73vw, 700px);
    height: clamp(0vw, 9.79vw, 130px);
    font-size: clamp(0vw, 5.27vw, 70px);
    border-radius: 15px;
  }

  .white_link {
    font-size: 14px;
  }

  .middle_size {
    width: 80%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .btn {
    width: clamp(0vw, 29.67vw, 540px);
    height: clamp(0vw, 3.76vw, 50px);
    font-size: clamp(0vw, 1.5vw, 20px);
    font-weight: bold;
  }

  .big_size {
    font-size: clamp(0vw, 2.25vw, 30px);
    font-weight: 700;
    width: 49%;
    height: clamp(0vw, 4.89vw, 65px);
  }

  .white_link {
    font-size: 14px;
  }

  .middle_size {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .btn {
    width: clamp(0vw, 18.06vw, 340px);
    height: clamp(0vw, 3.01vw, 40px);
    font-size: clamp(0vw, 1.5vw, 20px);
    font-weight: bold;
  }

  .big_size {
    font-size: clamp(0vw, 2.25vw, 30px);
    font-weight: 700;
    width: 49%;
    height: clamp(0vw, 4.89vw, 65px);
  }

  .white_link {
    font-size: 14px;
  }

  .middle_size {
    width: 80%;
  }
}
